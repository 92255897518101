export const setting = {
    bgcolor : "#49a7a7",
    title : window.location.hostname.replaceAll("www.","").split(".")[0].toUpperCase(),
    lp :"https://ltdquiz.com/check/",
    google_gtm : "GTM-WGQRXZ9",
    google_aw :"AW-315935574",
    utm_accountId:"141-541-3783",
    utm_account:"Airtrade",
    domain : window.origin+"/",
    conversion: "AW-315935574/-aUxCJ6q_IkDENaW05YB",
    shortLink :"https://whtapp.co/p-pGnKXOkw",
    email : "info@"+window.location.hostname.replaceAll("www.","")
}
